html {
  overflow-x: hidden;
}

body {
  /* letter-spacing: 0.1em; */
  letter-spacing: 0.15em;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/857f80ccfab5f8e2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/7db862de4f9849c3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/477e0f2693c22fb5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__NotoSansJP_Fallback_128efb';src: local("Arial");ascent-override: 110.26%;descent-override: 27.38%;line-gap-override: 0.00%;size-adjust: 105.20%
}.__className_128efb {font-family: '__NotoSansJP_128efb', '__NotoSansJP_Fallback_128efb', system-ui, arial
}.__variable_128efb {--noto-sans-jp: '__NotoSansJP_128efb', '__NotoSansJP_Fallback_128efb', system-ui, arial
}

@font-face {
font-family: '__Overpass_ef60d2';
src: url(/_next/static/media/1356c522a52eba4f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Overpass_ef60d2';
src: url(/_next/static/media/cd2e9c412e3f2420-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__Overpass_ef60d2';
src: url(/_next/static/media/6aa9fbad9f33b18d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__Overpass_Fallback_ef60d2';src: local("Arial");ascent-override: 89.83%;descent-override: 38.97%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_ef60d2 {font-family: '__Overpass_ef60d2', '__Overpass_Fallback_ef60d2', system-ui, arial
}.__variable_ef60d2 {--overpass: '__Overpass_ef60d2', '__Overpass_Fallback_ef60d2', system-ui, arial
}

